<template>
  <ion-page class="page scrollable bg-transparent pb-5">
    <Breadcrumbs :breadcrumbs="ocsReadyBreadcrumbs" />

    <PageHeader class="mt-4" :title="sectionTitle" :subtitle="subtitle" invert />

    <img loading="lazy" src="/images/header_OCs.png" class="mt-3 borders" />

    <ParagraphWithTitle class="mt-4" :title="p1.title" :body="p1.body" />
    <div class="w-50 spacer mt-3 ml-2" />
    <ParagraphWithTitle class="mt-4" :title="p2.title" :body="p2.body" />
    <div class="w-50 spacer mt-3 ml-2" />
    <ParagraphWithTitle class="mt-4" :title="p3.title" :body="p3.body" />

    <Resources class="mt-4" title="Original Character Resources:" :resources="resources" />

    <section class="buttons mt-5">
      <div class="d-flex justify-content-between">
        <TitleWithAction title="Featured Original Character Art and Designs" action class="mb-3" />
        <router-link :to="{ name: 'original-character-art' }">
          <ion-button color="primary" class="action-btn" fill="clear"> See More OC Art </ion-button>
        </router-link>
      </div>
      <div class="text mt-n1">
        We're so lucky to have incredible original character artists from around the world! Enjoy some featured art from
        our OC community! Submit your characters and art on the site to have a chance to be featured here!
      </div>

      <FanArtGrid class="mt-4" :fan-arts="fanArts" />
    </section>
    <section class="buttons mt-5">
      <h2 class="title mb-3">Featured Original Characters</h2>
      <div class="mt-n2">
        Check out these incredible OCs! These OCs are not only selected for their great art, but also for the lore and
        character details! Check out the character profiles to learn more about the characters and their creators!
      </div>
      <InfiniteCharacters
        class="mt-2"
        :characters="characters"
        :loading="false"
        :paging="{}"
        :hideTypeTag="true"
        :showCharacterTags="true"
        :pagination="false"
        :layout="'list'"
      />
      <div class="text-center">
        <ion-button v-if="hasMoreChars" class="clickable" @click="loadMoreCharacters">Load More</ion-button>
      </div>
    </section>
    <section class="buttons mt-4 px-2">
      <TitleWithAction title="Explore more" class="mb-2" />
      <ButtonGrid
        :style="{ '--background': '#214163', textTransform: 'unset', '--border-radius': '20px' }"
        :buttons="links"
      />
    </section>
  </ion-page>
</template>

<script lang="ts" setup>
import { Character, Paging } from '@/shared/types/static-types';
import Breadcrumbs from '@/shared/components/breadcrumbs.vue';
import TitleWithAction from '@/shared/components/title-with-action.vue';
import PageHeader from '@/shared/components/page-header.vue';
import ParagraphWithTitle from '@/shared/components/paragraph-with-title.vue';
import Resources from '@/shared/components/resources.vue';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import FanArtGrid from '@/shared/components/fan-art-grid.vue';
import ButtonGrid from '@/shared/components/button-grid.vue';
import image from '../../../public/images/header_OCs.png';

import { getOriginalCharacterIndexPage } from '@/shared/actions/public';
import { getNextPage } from '@/shared/helpers/pagination';

const ocsBreadcrumbs = ref([
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Original Characters',
    href: '/original-character',
    isActive: true,
  },
]);
const fanArts = ref<any[]>();
const hasMoreChars = computed(() => !!charsPaging.value?.next);
const sectionTitle = ref('Original Characters (OCs):');
const title = ref('Original Character (OCs) - CharacterHub');
const subtitle = ref('A Comprehensive Guide with Resources and Examples');
const characters = ref<Character[]>([]);
const charsPaging = ref<Paging | null>(null);
const pageData = useState<any>('pageData', () => ({}));

const p1 = ref({
  title: 'What are original characters, a.k.a. OCs?',
  body: `<article><p>
          An original character (often abbreviated as "OC") is a fictional character that is not taken from an existing
          body of work. They are characters created by the artist or writer themselves.
        </p>
        <p>
          Quick! Think of a random animal. Now give it some unique features, a personality, and a name. You just created
          an original character! Fun right? Original characters are often used in stories, art, Dungeons & Dragons, and
          more!
        </p>
        <p>
          Fan characters or fandom OCs are also popular. These original characters live in an existing world or
          universe. For example, an original character that lives in Hogwarts and hangs out with Harry Potter would be a
          fan character. Fan characters often appear in fanfiction and fanart!
        </p></article>`,
});

const resources = ref([
  {
    label: 'How To Create An Epic OC',
    href: 'https://characterhub.com/blog/character-resources/make-an-oc',
  },
  {
    label: 'Getting Character Design Inspo',
    href: 'https://characterhub.com/blog/character-resources/character-inspiration',
  },
  {
    label: 'Character Bio Sheet (250+ trait ideas)',
    href: 'https://characterhub.com/blog/character-resources/character-template',
  },
  {
    label: '101 Character Design Ideas',
    href: 'https://characterhub.com/blog/oc-ideas/character-design-ideas',
  },
  {
    label: '1001 Character Traits To Use',
    href: 'https://characterhub.com/blog/oc-ideas/character-ideas',
  },
  {
    label: 'How Shapes Make The Character',
    href: 'https://characterhub.com/blog/character-resources/shape-drven-oc-design',
  },
  {
    label: 'How To Make A Character Reference Sheet',
    href: 'https://characterhub.com/blog/character-resources/oc-reference-sheet',
  },
  {
    label: 'How To Make A Character Color Palette',
    href: 'https://characterhub.com/blog/character-resources/character-color-palette',
  },
  {
    label: 'OC Writing Prompts',
    href: 'https://characterhub.com/blog/oc-ideas/writing-prompts',
  },
  {
    label: 'OC Challenges You Can Join Right Now',
    href: 'https://characterhub.com/blog/oc-ideas/oc-challenge',
  },
]);

const p2 = ref({
  title: 'How do you create an amazing original character?',
  body: `<p>Getting started can be simple. Have fun! Think about characters and stories that resonate with you, and then go wild!</p>
  <h3>Step 1: Look for inspiration</h3>
  <p>Study people in your life. Study yourself. Look at objects and animals. Even look at other characters (but don't steal any ideas!) You never know what might spark inspiration.</p>
  <h3>Step 2: Think like your character</h3>
  <p>Once you have a concept, try to think from your character's perspective. We often base characters on ourselves or people we know. Try to get into the character's headspace and view the world as they do. Experience the world as they do. This makes for a stronger character.</p>
  <h3>Step 3: Think about their relationships, desires, and obstacles</h3>
  <p>Great characters need great stories! Think about the character's family and friends. Think about what they want and why. What do they need? Finally, think about what's getting in their way. Even consider how they are getting in their own way.</p>
  <p>Read our detailed guide about <a rel="follow" class="link" href="https://characterhub.com/blog/character-resources/make-an-oc">how to make an original character</a> for detailed instructions about ideation, character art, and more!</p>
    <h3>Want deeper advice? Let's turn to the experts!</h3>
    <p>According to Kellye Perdue, Storyboard Artist at Warner Bros Animation:</p>
<p><i>"For beginners, I would tell them to think about what kind of story they're trying to tell. For example, if you're designing a character that's supposed to go on an adventure and slay a monster, maybe they look muscular and cool, like Jacob in the movie The Sea Beast. Opposite of that you could have a story with an "unlikely" where maybe the main character has to go slay a monster, but maybe they're reluctant to do so or they aren't as strong as the people around them, ala Shrek or Hiccup from How to Train Your Dragon. All of the designs service the role the character is meant to play in the story. And that goes for all the characters and even the environment around them, as well. Another tip is to just look at the things that they already enjoy. Types of characters, fashion, art in general, etc. and go off of that. It'll help inform them on their own likes and dislikes, and create something that is uniquely theirs in their work."</i></p>
<p>Mark Lawrence, the Award-winning novelist of The Broken Empire trilogy, puts it simply:</p>
<p><i>"A good character doesn't give a damn about your story. They're not here to help. You didn't make them - they just blew into town. Your job is to shut up, watch what they do, write it down."</i></p>`,
});

const p3 = ref({
  title: 'CharacterHub is for Original Characters!',
  body: `<p>
          <a href="/">CharacterHub</a> gives you a free place to keep track of and showcase all your character details
          and art! Whether you're an artist, writer, animator, comic creator, or VTuber, we've got something for you!
        </p>
        <p>
          We have a huge list of traits to help you develop your character further (blood type? favorite color? we got
          it all!) You can even add a Spotify playlist for them!
        </p>
        <p>
          The community is our superpower. We've built unique experiences to help support original characters through
          community:
        </p>
        <ul>
          <li>
            <p>
              <strong>Compliment Bombs:</strong> a place where we spotlight characters to get compliments from the
              entire community!
            </p>
          </li>
          <li>
            <p>
              <strong>Cheers:</strong> Where your friends and fans can cheer for you as you build your character, upload
              your art, and create your world! As they cheer, you can get spotlighted to the entire community!
            </p>
          </li>
          <li>
            <p>
              <strong>Events and contests:</strong> We host regular events, contests, challenges, and prompts to
              showcase new characters to the community!
            </p>
          </li>
        </ul>
        <p><a rel="follow" class="link" href="/signup">You can join the community here!</a></p>`,
});

const links = ref<any[]>([{ label: 'See More OC Art', href: '/original-character-art' }]);

const ocsReadyBreadcrumbs = computed(() => {
  return [...ocsBreadcrumbs.value];
});

const loadMoreCharacters = async () => {
  if (!charsPaging.value?.next) return;
  const { results, ...paging } = await getNextPage(charsPaging.value);
  charsPaging.value = paging as Paging;
  characters.value = [...characters.value, ...results.map((featuredChar: any) => featuredChar.character)];
};

const {
  public: { baseCDNUrl },
} = useRuntimeConfig();

const picture = ref(`${baseCDNUrl}${image}`);
const description = ref(
  `Discover thousands of original characters! Original characters (OCs) are characters created by an author or artist! Some characters are completely original and may be in the creators' stories or comics. Other characters, known as fan characters, may be based on or exist in the universes of existing media.`
);
const pageTitle = ref('Original Character (OCs) - CharacterHub');
const allowCrawlers = ref(true);
const allowAiBots = ref(false);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);

function formatLinks(tups: any[], isFandom = false) {
  if (isFandom) {
    return (
      tups.map((tup) => {
        return {
          href: '/fandoms/' + tup[1],
          label: tup[0],
        };
      }) || []
    );
  }

  return (
    tups?.map((tup) => {
      if (tup.length === 3 && tup[2] === 'oc') {
        return {
          href: '/original-character/' + tup[1],
          label: tup[0] + ' OCs',
        };
      }
      return {};
    }) || []
  );
}

onMounted(() => {
  const document = useDocument();
  if (!document.value) return;
  document.value.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth' });
});

await useChAsyncData(async () => {
  pageData.value = await getOriginalCharacterIndexPage();
  const { results, ...paging } = pageData.value?.featured_characters || [];
  characters.value = results.map((featuredChar: any) => featuredChar.character);
  charsPaging.value = paging;
  fanArts.value = pageData.value?.featured_fanart?.results || [];
  if (pageData.value.fanart?.count) {
    picture.value = fanArts.value![0]?.image?.image || picture.value;
  }
  let linksToAppend = [] as any[];

  for (let key in pageData.value.oc_sections) {
    // { label: [(Name1, slug1, 'oc')] }
    linksToAppend = [...linksToAppend, ...formatLinks(pageData.value.oc_sections[key])];
  }
  linksToAppend = [...linksToAppend, ...formatLinks(pageData.value.fandoms, true)];
  links.value = [...links.value, ...linksToAppend];
  useChHead(pageTitle, title, description, url, picture, allowCrawlers, allowAiBots);
  return true;
});
</script>

<style lang="sass" scoped>
.borders
  border-radius: 12px
.spacer
  border-bottom: 3px solid #AE38E5
h1
  margin: 2rem auto
.page
  ::v-deep
    h3
      font-size: 16px !important
      font-weight: bold !important
    p
      margin: 10px 0
article
  background: rgba(121, 121, 121, 0.2)
  padding: 1rem
  border-radius: 10px
.title
  font-size: 1.5rem
  font-weight: bold
  margin-bottom: 3rem
.fandom
  border-radius: 16px
  background: rgba(121, 121, 121, 0.2)
  padding: 0.4rem 1rem
  font-size: 0.8rem
  min-width: 100px
  text-align: center
.fanart
  object-fit: cover
  width: 100%
  height: 100%
.fan-art-card
  border-radius: 10px
  height: 300px
.art-link
  font-weight: bold
.art-caption
  text-align: center
</style>
